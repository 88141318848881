<template>
  <div>
    <van-row>
      <van-search
        v-model="search.PYDM"
        shape="round"
        show-action
        input-align="center"
        placeholder="请输入药品名称"
        :clearable="false"
        @search="fetchData"
      >
        <template #action>
          <div @click="fetchData">搜索</div>
        </template>
      </van-search>
    </van-row>
    <div v-if="list.length > 0" class="appointmentList">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="title">
          {{ item.drugName }}
        </div>
        <div class="list">
          <div class="grid-table">
            <div>药品零售价</div>
            <div>{{ item.drugFee }}(元)</div>
            <div>药品规格</div>
            <div>{{ item.drugSpecs }}</div>
            <div>药品单位</div>
            <div>{{ item.drugUnit }}</div>
            <div>产地名称</div>
            <div>{{ item.drugAddressName }}</div>
            <div>产地代码</div>
            <div>{{ item.drugAddress }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无药品信息，请重新查询" />
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import { Notify, Toast } from 'vant'
  import { getDrugList } from '@/api/his/his'
  export default {
    name: 'Drug',
    data() {
      return {
        active: '全部',
        list: [],
        search: {
          PYDM: '',
        },
      }
    },
    async created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
    },

    methods: {
      async fetchData() {
        if (this.search.PYDM === '')
          Notify({ type: 'warning', message: '搜索项不可为空', duration: 1000 })
        else {
          Toast.loading({
            duration: 0, // 持续展示 toast
            message: '正在加载中...',
            forbidClick: true,
          })
          const { data } = await getDrugList(this.search)
          this.list = data
          Toast.clear()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .van-search {
    width: 95%;
    border-radius: 10px;
    margin: 10px auto 0;
  }
  .van-tabs {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
  }
  .appointmentList {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    font-family: 'Microsoft Yahei';
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
</style>
